import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { LAYOUT_SIZES } from 'Style/layout';

// Utils
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { getProfilePath } from 'Utils/content/flipboard-urls';

// Components
import WebLink from './web-link';
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import VerifiedCheckmarkIcon from 'ComponentLibrary/icons/verified-checkmark';
import FavoriteButton from '../containers/section-header/favorite-button';
import AuthorAvatar from './attribution/author-avatar';
import FollowButton from 'Webapp/shared/app/components/follow-button';
import CopyFederatedUsername from 'Webapp/shared/app/components/CopyFederatedUsername';

import withShare from '../hocs/withShare';
import withFlip from '../hocs/withFlip';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

const PublisherHeading = codeSplitLoad('PublisherHeading');
const ProfileMetricsSubNav = codeSplitLoad('ProfileMetricsSubNav');

export const HEADER_THEMES = {
  SIMPLE: 'simple',
  EXTENDED: 'extended',
};

const StyledHeader = styled.header({
  display: 'grid',
  gridTemplateColumns: 'max-content minmax(0px, auto) max-content',
  alignItems: 'flex-start',
});

const StyledHeaderContents = styled.div(
  ({ apEnabled }) => ({
    paddingBottom: apEnabled ? SPACING.BASE6X : 0,
  }),
  {
    position: 'relative',
  },
);

const StyledAuthorDescription = styled.p(UI_TEXT_TYPES.BODY_2, {
  maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
  wordBreak: 'normal',
  overflowWrap: 'anywhere',
  marginBottom: SPACING.MEDIUM,
});

const HeaderTitle = styled.h1(
  { alignItems: 'center' },
  UI_TEXT_TYPES.PAGE_TITLE,
);

const ProfileHeaderActions = styled.div({
  display: 'flex',
  gap: SPACING.MEDIUM,
  paddingBottom: SPACING.MEDIUM,
  maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
});

const FederatedUsername = styled.div({
  position: 'absolute',
  right: 0,
  bottom: 0,
});

function ProfileHeader({
  section,
  userStats,
  publisher,
  isOwnProfile,
  onFlip,
  onShare,
  isAmp,
  className,
  variant,
  linkToProfile,
  t,
}) {
  const { authorDisplayName, authorDescription, authorUsername } = section;
  const shouldShowFollow = !isOwnProfile && authorDisplayName;
  const isExtendedTheme = variant === HEADER_THEMES.EXTENDED;
  const isSimpleTheme = variant === HEADER_THEMES.SIMPLE;
  const profileUrl = getProfilePath(section);
  const classes = classNames(className, {
    'profile-header': true,
    'profile-header--simple': isSimpleTheme,
  });
  const profileAvatar = linkToProfile ? (
    <WebLink
      href={profileUrl}
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
    >
      <AuthorAvatar
        containerClass="profile-header__author-avatar"
        author={section}
        avatarSize={80}
      />
    </WebLink>
  ) : (
    <AuthorAvatar
      containerClass="profile-header__author-avatar"
      author={section}
      avatarSize={80}
    />
  );
  const userName =
    authorDisplayName.trim().length > 0 ? authorDisplayName : authorUsername;
  const authorName = linkToProfile ? (
    <WebLink
      href={profileUrl}
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
    >
      {userName}
    </WebLink>
  ) : (
    userName
  );
  return (
    <StyledHeader className={classes}>
      {profileAvatar}
      <StyledHeaderContents apEnabled={section.apEnabled}>
        <div className="profile-header__title-wrapper">
          {isOwnProfile && publisher ? (
            <PublisherHeading
              publisher={publisher}
              authorDisplayName={userName}
              section={section}
            />
          ) : (
            <HeaderTitle>
              {authorName}
              {section.isVerifiedPublisher && (
                <VerifiedCheckmarkIcon className="profile-header__icon" />
              )}
            </HeaderTitle>
          )}
        </div>
        {authorDescription && (
          <StyledAuthorDescription>{authorDescription}</StyledAuthorDescription>
        )}
        {isExtendedTheme && (
          <ProfileHeaderActions>
            {shouldShowFollow && (
              <FollowButton
                namePrefix="profile-header"
                section={section}
                styleModifier={[StyleModifiers.INVERSE]}
              />
            )}
            {!isOwnProfile && <FavoriteButton section={section} />}
            {!isAmp && (
              <Button
                onClick={() => onShare({ section })}
                name="profile-header-share"
                label={t('share')}
                styleVariation={StyleVariations.SECONDARY}
              />
            )}
            {!isAmp && (
              <Button
                onClick={() => onFlip({ section })}
                name="profile-header-flip"
                label={t('flip')}
                styleVariation={StyleVariations.SECONDARY}
              />
            )}
          </ProfileHeaderActions>
        )}
        {isExtendedTheme && (
          <ProfileMetricsSubNav
            section={section}
            metrics={userStats || section.metrics}
            isOwnProfile={isOwnProfile}
          />
        )}
        {section.apEnabled && (
          <FederatedUsername>
            <CopyFederatedUsername
              username={`@${section.authorUsername}@flipboard.com`}
              tKey="profile_federated_username_copied"
            />
          </FederatedUsername>
        )}
      </StyledHeaderContents>
    </StyledHeader>
  );
}

ProfileHeader.propTypes = {
  isAmp: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  userStats: PropTypes.object,
  publisher: PropTypes.object,
  isOwnProfile: PropTypes.bool,
  onFlip: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(HEADER_THEMES)),
  className: PropTypes.string,
  linkToProfile: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ProfileHeader.defaultProps = {
  userStats: null,
  publisher: null,
  isOwnProfile: false,
  variant: HEADER_THEMES.EXTENDED,
  className: '',
  linkToProfile: false,
};

export default connector(connectAmp)(withT(withShare(withFlip(ProfileHeader))));
